import axios from "axios";
import { useState } from "react"


export const ContactForm: React.FC = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [content, setContent] = useState("");
    
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const sendMessage = (e: any) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        axios({
            url: "/api/feedback/full",
            method: "post",
            data: {
                name,
                phone,
                content
            }
        }).then(() => {
            setSuccess(true);
        }).catch(err => {
            console.error(err);
            setError(true);
        }).finally(() => {
            setLoading(false);
        })
    }

    const isActive = name.length && content.length && phone.length;

    return (
        <form className="contact-form" onSubmit={sendMessage}>
            {
                error
                    ? (
                        <div className="contact-form-error">
                            Проверьте правильность заполнения полей
                        </div>
                    ) : null
            }
            <input
                name="name"
                className="contact-form-input"
                placeholder="Ваше имя"
                disabled={success || loading}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <input
                name="phone"
                className="contact-form-input"
                placeholder="Номер телефона"
                disabled={success || loading}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
            />
            <textarea
                name="content"
                className="contact-form-input textarea"
                placeholder="Сообщение"
                disabled={success || loading}
                value={content}
                onChange={(e) => setContent(e.target.value)}
            />
            <button
                type="submit"
                className={`main-btn ${loading ? "loading" : ""}`}
                disabled={!isActive || success}
                children={success ? `С вами свяжется наш менеджер` : `Отправить сообщение`}
            />
        </form>
    )
}