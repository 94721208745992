import { Link } from "react-router-dom"
import { CDN_BASE } from ".."
import { Estate } from "../api/types"
import { StarFilled } from "@ant-design/icons"

export const EstateCard: React.FC<{estate: Estate}> = ({estate}) => {
    return (
        <div className="slide estate-slide">
            <Link
                to={`/catalog/${estate._id}`}
                className="estate-photo"
                style={{
                    backgroundImage: `url(${CDN_BASE + estate.cover})`
                }}
            />
            <h4 className="estate-title">
                <span>{estate.title}</span>
                {
                    estate.starred ? <StarFilled className="star" /> : null
                }
            </h4>
            <p className="estate-location">{estate.place}</p>
            <div className="estate-row">
                <span className="estate-floors">{estate.floors}</span>
                <span className="estate-bedrooms">{estate.bedrooms}</span>
                <span className="estate-square">{estate.square}</span>
            </div>
            <div className="estate-row last-row">
                <span className="estate-price">{estate.price.toLocaleString("en", {style: "currency", currency: "usd"})}</span>
                <Link
                    className="link-btn"
                    to={`/catalog/${estate._id}`}
                    children="Подробнее"
                />
            </div>
        </div>
    )
}