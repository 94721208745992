
export interface TeamCardProps {
    photo: string
    name: string
    position: string
}

const CARDS: TeamCardProps[] = [
    {
        photo: "/img/team/team3.jpeg",
        name: "Иван",
        position: "Учредитель, Глава отдела Продаж",
    },
    {
        photo: "/img/team/team2.jpeg",
        name: "Алиса",
        position: "Представитель в РФ",
    },
    {
        photo: "/img/team/team4.jpeg",
        name: "Илья",
        position: "Учредитель. Глава отдела Маркетинга",
    },
    {
        photo: "/img/team/team1.jpg",
        name: "Александр",
        position: "Менеджер по продажам",
    },
]


const TeamCard: React.FC<TeamCardProps> = (props) => {
    return (
        <div className="team-card">
            <div className="card-photo" style={{
                backgroundImage: `url('${props.photo}')`
            }} />
            <div className="card-name">{props.name}</div>
            <div className="card-position">{props.position}</div>
        </div>
    )
}

export const TeamCards: React.FC = () => {
    return (
        <div className="team-cards">
            {
                CARDS.map(card => <TeamCard {...card} key={card.photo} />)
            }
        </div>
    )
}