import { useState } from "react"

export interface LineSelectorOption {
    title: string
    value: string
}

export interface LineSelectorProps {
    value: string
    options: LineSelectorOption[]
    onChange: (value: string) => void
}

export const LineSelector: React.FC<LineSelectorProps> = (props) => {
    const [lineOffset, setLineOffset] = useState(() => props.options.findIndex(option => option.value === props.value));
    const option_width = 100 / props.options.length;

    return (
        <div className="line-selector-wrapper">
            {
                props.options.map((option, i) => (
                    <div 
                        key={"ln-o-" + option.value}
                        className={`line-selector-option ${props.value === option.value ? "active" : ""}`} 
                        onClick={() => {
                            props.onChange(option.value)
                            setLineOffset(i)
                        }}
                        children={option.title}
                    />
                ))
            }
            <div className="line-selector-line-wrapper">
                <div
                    className="line-selector-line"
                    style={{
                        width: `${option_width}%`,
                        transform: `translateX(${lineOffset * 100}%)`
                    }}
                />
            </div>
        </div>
    )
}