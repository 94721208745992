import { ContactForm } from "../components/contact-form";
import { ContactMap } from "../components/contact-map";

const ContactPage: React.FC = () => {

    return (
        <div className="page-content">
            <section className="jumbo-wrapper contact-jumbo">
                <div className="jumbo content-wrapper">
                    <h2 className="heading">Контакты</h2>
                </div>
            </section>
            <div className="contact-page-wrapper content-wrapper">
                <div className="contact-page">
                    <div className="contact-about">
                        <h2 className="title">Свяжитесь с нами</h2>
                        <p className="par">Оставьте свои контактные данные в форме, и мы свяжемся с Вами в ближайшее время.</p>
                        <p className="par">Наша команда подберет варианты под ваш бюджет.</p>
                        <p className="par">Мы подбираем для наших клиентов варианты на начальном этапе строительства с крупными скидками с максимальными перспективами роста стоимости на этапе строительства, которые можно перепродать на 40% дороже еще до готовности объекта.</p>
                        <p className="par">Максимально выгодные арендные программы с гарантированным доходом 7-8% годовых и программы с фактическим доходом до 12% годовых. Выплаты по некоторым программам начинаются уже на этапе строительства!</p>
                    </div>
                    <div className="contact-form-wrapper">
                        <ContactForm />
                    </div>
                </div>
            </div>
            <ContactMap />
        </div>
    )
}

export default ContactPage;
