import { Pagination } from "swiper/modules"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import { CDN_BASE } from ".."
import { useEffect, useState } from "react";

const GalleryControls: React.FC = () => {
    const swiper = useSwiper();

    const [isBeginning, setIsBeginning] = useState(swiper.isBeginning);
    const [isEnd, setIsEnd] = useState(swiper.isEnd);

    useEffect(() => {
        const onIndexChange = () => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
        }

        swiper.on("activeIndexChange", onIndexChange);

        return () => {
            swiper.off("activeIndexChange", onIndexChange);
        }

    }, [swiper]);

    return (
        <div className="gallery-controls">
            <button
                className="slider-slide-btn prev"
                disabled={isBeginning}
                onClick={() => swiper.slidePrev()}
                children={
                    <img src="/img/angle-left.svg" className="prev-btn" alt="<" /> 
                }
            />
            <button
                className="slider-slide-btn next"
                disabled={isEnd}
                onClick={() => swiper.slideNext()}
                children={
                    <img src="/img/angle-right.svg" className="next-btn" alt=">" /> 
                }
            />
        </div>
    )
}

export const PhotoSlider: React.FC<{photos: string[]}> = ({photos}) => {
    return (
        <div className="photo-slider-wrapper">
            <Swiper
                className="photo-slider"
                modules={[Pagination]}
                slidesPerView={1}
                pagination={{ clickable: true }}
            >
                {
                    photos.map(photo => (
                        <SwiperSlide key={photo} className="photo-slide" style={{
                            backgroundImage: `url("${CDN_BASE + photo}")`
                        }} />
                    ))
                }
                {
                    photos.length 
                        ? <GalleryControls /> 
                        : null 
                }
            </Swiper>
        </div>
    )
}