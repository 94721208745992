import { Link } from "react-router-dom"


export const AboutSection: React.FC = () => {
    return (
        <section className="about-section">
            <div className="content-wrapper">
                <h3 className="heading">О компании</h3>
                <p className="par">Мы поможем вам сформировать инвестиционную стратегию, подобрать объекты, соответствующие вашим потребностям, провести сделку с максимальной выгодой для вас, и даже поможем вам с перепродажей объектов для получения максимальной прибыли.</p>
                <p className="par">Наша команда профессионалов обеспечит вам высококачественный сервис и оперативную помощь в случае необходимости.</p>
                <Link
                    to="/about" 
                    className="arr-btn"
                    children="Узнать больше"    
                />
            </div>
        </section>
    )
}