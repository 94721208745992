import { AboutCards } from "../components/about-cards";
import { TeamCards } from "../components/team-cards";

const AboutPage: React.FC = () => {

    return (
        <div className="page-content">
            <section className="jumbo-wrapper about-jumbo">
                <div className="jumbo content-wrapper">
                    <h2 className="heading">О компании</h2>
                    <p className="par">Мы – экспертное агентство, специализирующееся на доходной недвижимости Таиланда. Мы являемся одной из ведущих компаний на рынке недвижимости Таиланда и предоставляем высококачественные услуги по покупке, продаже и аренде недвижимости.</p>
                    <p className="par">Мы понимаем, что инвестиции в недвижимость - это серьезное дело, поэтому мы предоставляем нашим клиентам максимально полезную информацию о том, как сделать правильный выбор при инвестировании в недвижимость в Таиланде. Наша команда состоит из опытных риэлторов, а так же высококвалифицированных экспертов отрасли, которые всегда готовы помочь нашим клиентам.</p>
                    <p className="par">Мы предлагаем широкий спектр услуг, включая продажу и покупку квартир, вилл, таунхаусов и земельных участков, а также аренду жилой и коммерческой недвижимости. Мы работаем с клиентами из разных стран мира и имеем многолетний опыт работы с иностранными инвесторами.</p>

                </div>
            </section>
            <div className="about-page-wrapper content-wrapper">
                <div className="about-page">
                    <h4 className="about-cards-title">Работая с нами, вы получаете полный спектр услуг, который включает в себя:</h4>
                    <AboutCards />
                    <h4 className="team-cards-title">Наша команда профессионалов:</h4>
                    <TeamCards />
                </div>
            </div>
        </div>
    )
}

export default AboutPage;
