
export interface ButtonsSelectorOption {
    title: string
    value: string
}

export interface ButtonsSelectorProps {
    value: string[]
    options: ButtonsSelectorOption[]
    onChange: (value: string[]) => void
}

export const ButtonsSelector: React.FC<ButtonsSelectorProps> = (props) => {
    return (
        <div className="buttons-selector-wrapper">
            {
                props.options.map(option => {
                    const active = props.value.includes(option.value);
                    return (
                        <button
                            key={'btns-o-' + option.value}
                            className={`buttons-selector-option ${active ? "active" : ""}`}
                            children={option.title}
                            onClick={() => props.onChange(active ? props.value.filter(x => x !== option.value) : [...props.value, option.value])}
                        />
                    )
                })
            }
        </div>
    )
}