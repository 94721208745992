import "../styles/preloader.sass";

export const Preloader: React.FC = () => {
    return (
        <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
        </div>
    );
}