import React, { FC, ReactNode } from 'react';
import '../styles/modal.sass';

export type ModalProps = {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
};

export const Modal: FC<ModalProps> = ({ show, onClose, children, title }) => {
  return (
    <div
      className={`modal-backdrop ${show ? 'show' : ''}`}
      onClick={e => {
        if(e.target === e.currentTarget) onClose();
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">
            {title}
          </div>
          <button className="close-button" onClick={onClose}>
            ×
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};