import axios from "axios";
import { useState } from "react";

export const RequestCall: React.FC = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const requestCall = (e: any) => {
        e.preventDefault();
        setLoading(true);
        axios({
            url: "/api/feedback/short",
            method: "post",
            data: { name, phone }
        }).then(({data}) => {
            setSuccess(true)
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <form className="request-call" onSubmit={requestCall}>
            <input
                name="name"
                className="request-call-input"
                disabled={success || loading}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Ваше имя"
            />
            <input
                name="phone"
                className="request-call-input"
                disabled={success || loading}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Номер телефона"
            />
            <button
                type="submit"
                disabled={phone.length < 8 || name.length < 1 || success || loading}
                className="main-btn"
                children={success ? "Звонок заказан!" : "Заказать звонок"}
            />
        </form>
    )
} 

export const Jumbo: React.FC = () => {
    return (
        <section className="jumbo-wrapper">
            <div className="jumbo content-wrapper">
                <h1 className="heading">Недвижимость в Тайланде для инвестиций и переезда</h1>
                <h2 className="subheading">Гарантированный доход от ваших вложений. Полное сопровождение</h2>
            </div>
            <div className="request-call-wrapper content-wrapper">
                <RequestCall />
            </div>
        </section>
    )
}