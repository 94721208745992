

export interface AboutCardProps {
    icon: string
    content: string
}

const CARDS: AboutCardProps[] = [
    {
        icon: "/img/chart-line-up.svg",
        content: "Конкретизацию целей покупки и бюджета, а также формирование инвестиционной стратегии, которая наилучшим образом соответствует вашим потребностям и запросам."
    },
    {
        icon: "/img/puzzle-piece-simple.svg",
        content: "Предоставление наиболее подходящих объектов, соответствующих выбранной стратегии, с учетом ваших предпочтений и требований."
    },
    {
        icon: "/img/tag.svg",
        content: "Абсолютно лучшие возможности получить выгодные условия сделки, включая рассрочки, скидки, кэшбеки и бонусы, для обеспечения максимальной выгоды для наших клиентов."
    },
    {
        icon: "/img/file-contract.svg",
        content: "Согласование контракта и привлечение юриста для независимой экспертизы, чтобы обеспечить юридическую чистоту сделки и защитить интересы наших клиентов."
    },
    {
        icon: "/img/life-ring.svg",
        content: "Поддержка наших клиентов на протяжении всего периода владения объектом, обеспечивая высококачественный сервис и оперативную помощь в случае необходимости."
    },
    {
        icon: "/img/badge-dollar.svg",
        content: "Информирование о росте цен на объекты и возможности перепродажи для получения максимальной прибыли от инвестиций. "
    }
]


const AboutCard: React.FC<AboutCardProps> = (props) => {
    return (
        <div className="about-card">
            <div className="icon" style={{
                backgroundImage: `url('${props.icon}')`
            }} />
            <div className="card-content">{props.content}</div>
        </div> 
    )
}

export const AboutCards: React.FC = () => {
    return (
        <section className="about-cards">
            {
                CARDS.map(card => <AboutCard {...card} key={card.icon} />)
            }
        </section>
    )
}