import { useEffect, useState } from "react";
import { Estate } from "../api/types";
import axios from "axios";
import { Preloader } from "../components/preloader";
import { useNavigate, useParams } from "react-router-dom";
import { EstateGallery } from "../components/estate-gallery";
import { EstateSlider } from "../components/estate-slider";
import { FeedbackModal } from "../components/feedback-modal";
import { EstateMap } from "../components/estate-map";
import { BlockContactForm } from "../components/block-contact-form";
import { Microdata } from "../components/microdata";

const EstateContent: React.FC<{estate: Estate}> = ({estate}) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
        <FeedbackModal
            show={modalOpen}
            onClose={() => setModalOpen(false)}
        />
        <div className="estate-content content-wrapper">
            <Microdata
                title={estate.title}
                description={estate.description}
                price={estate.price}
                id={estate._id}
                url={`https://phuket-estate.ru/catalog/${estate._id}`}
                image={estate.cover || ""}
            />
            <div className="estate-price-wrapper">
                <div className="left-side">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div className="estate-price">{estate.price.toLocaleString("en", {style: "currency", currency: "usd"})}</div>
                        <div className="per-square">{(estate.price / estate.square).toLocaleString("en", {style: "currency", currency: "usd"})} / м²</div>
                    </div>
                    <p className="estate-location">{estate.place}</p>
                </div>
                <button
                    className="main-btn"
                    onClick={() => setModalOpen(true)}
                    children="Получить консультацию"
                />
            </div>
            <div className="estate-info-wrapper">
                <div className="estate-about-text">
                    <div className="about-title">Об объекте:</div>
                    {estate.description}
                </div>
                <div className="estate-info">
                    <div className="info-item">
                        <div className="info-label">Год постройки:</div>
                        <div className="info-value">{estate.yearBuild}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">Проект:</div>
                        <div className="info-value">{estate.buildStage === "Building" ? "На стадии строительства" : "Построен"}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">Спален:</div>
                        <div className="info-value">{estate.bedrooms}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">Этажей:</div>
                        <div className="info-value">{estate.floors}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">Общая площадь:</div>
                        <div className="info-value">{estate.square}м²</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">До пляжа:</div>
                        <div className="info-value">{estate.distanceToTheBeach}м</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">Доходность:</div>
                        <div className="info-value">{estate.profitability}</div>
                    </div>
                </div>
            </div>
            <EstateGallery
                blueprints={estate.blueprints}
                gallery={estate.gallery}
            />
            <div className="estate-desc-wrapper">
                <EstateMap
                    lat={estate.location.lat}
                    lng={estate.location.lng}
                />
                <BlockContactForm />
            </div>
        </div>
        </>
    )
}

const EstatePreloader: React.FC = () => {
    return (
        <div className="estate-preloader">
            <Preloader />
        </div>
    )
}

const EstatePage: React.FC = () => {
    const { id } = useParams();
    const [estate, setEstate] = useState<Estate | null>(null);
    const [loading, setLoading] = useState(true);
    const [estates, setEstates] = useState<Estate[]>([]);

    const navigate = useNavigate();
    
    useEffect(() => {
            axios<Estate[]>({
                url: "/api/real-estate",
                method: "get"
            }).then(({data}) => {
                setEstates(data);
                setLoading(false);
            }).catch(err => {
                console.error(err);
            })
    }, []);

    useEffect(() => {
        axios<Estate>({
            url: `/api/real-estate/${id}`,
            method: "get"
        }).then(({data}) => {
            setEstate(data);
            console.log("estate", data)
            setLoading(false);
        }).catch(err => {
            navigate("/catalog", {
                replace: true
            });
        })
    }, [id, navigate]);

    return (
        <>
        <div className="page-content">
            <section className="jumbo-wrapper estate-jumbo">
                <div className="jumbo content-wrapper">
                    <h2 className="heading">{estate?.title}</h2>
                </div>
            </section>
            <div className="estate-content-wrapper content-wrapper">
                {
                    loading
                        ? <EstatePreloader />
                        : estate
                            ? <EstateContent estate={estate} />
                            : <Preloader />
                }
                <EstateSlider
                    title="Вас также могут заинтересовать"
                    loading={loading}
                    estates={estates.slice(0,5)}
                />
            </div>
        </div>
        </>
    )
}

export default EstatePage;