import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { Preloader } from './preloader';

export interface EstateMapProps {
    lat: number
    lng: number
}

export const EstateMap: React.FC<EstateMapProps> = (props) => {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: "AIzaSyCFhY7JXfhHuwIxiej_eYQMNNwRyjAfj5o",
    });
    
    return (
        <div className='estate-map-wrapper'>
            {
                isLoaded ?
                <GoogleMap
                    mapContainerClassName='estate-map'
                    center={props}
                    zoom={12}
                    options={{
                        fullscreenControl: false,
                        streetViewControl: false,
                    }}
                >
                    <MarkerF
                        position={props}
                    />
                </GoogleMap>
                :
                <Preloader />
            }
        </div>
    )
}