import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from "./pages";
import './styles/index.sass';
import { BrowserRouter } from 'react-router-dom';

export const CDN_BASE = "https://estates.fra1.cdn.digitaloceanspaces.com/"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  // </React.StrictMode>
);