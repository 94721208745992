
export interface DropdownSelectorOption {
    title: string
    value: string
}

export interface DropdownSelectorProps {
    value: string
    options: DropdownSelectorOption[]
    onChange: (value: string) => void
}

export const DropdownSelector: React.FC<DropdownSelectorProps> = (props) => {
    return (
        <div className="dropdown-selector-wrapper">
            <select
                className="dropdown-selector"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
            >
            {
                props.options.map(option => (
                    <option key={"dropdown-o-" + option.value} value={option.value} children={option.title} />
                ))
            }
            </select>
        </div>
    )
}