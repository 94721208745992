import { PhotoSlider } from "../components/photo-slider";
import { LineSelector } from "../components/line-selector";
import { useState } from "react";

const GALLERY_OPTION = ({
    title: "Фото",
    value: "gallery"
});

const BLURPRINTS_OPTION = ({
    title: "Планировка",
    value: "blueprints"
});

const EstateGalleryEmpty: React.FC = () => {
    return (
        <div className="estate-gallery-empty">
            <span className="estate-gallery-empty-label">Нет фото</span>
        </div>
    )
}

export const EstateGallery: React.FC<{
    blueprints: string[],
    gallery: string[]
}> = (photosets) => {
    const [sliderCategory, setSliderCategory] = useState<"gallery" | "blueprints">("gallery");

    const shouldDisplaySelector = photosets.blueprints.length && photosets.gallery.length;

    const OPTIONS = shouldDisplaySelector
        ? [GALLERY_OPTION, BLURPRINTS_OPTION]
        : photosets.gallery.length
            ? [GALLERY_OPTION]
            : photosets.blueprints.length
                ? [BLURPRINTS_OPTION]
                : []
    
    const currentSet = shouldDisplaySelector 
        ? photosets[sliderCategory]
        : photosets.gallery.length
            ? photosets.gallery
            : photosets.blueprints.length
                ? photosets.blueprints
                : []

    return (
        <section className="estate-gallery">
            {   
                shouldDisplaySelector
                    ?
                    <LineSelector
                        value={sliderCategory}
                        onChange={setSliderCategory as any}
                        options={OPTIONS}
                    />
                    :
                    null
            }{
                currentSet.length
                    ? <PhotoSlider photos={currentSet} />
                    : <EstateGalleryEmpty />
            }
        </section>
    )
}