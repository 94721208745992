import axios from "axios";
import { useState } from "react";

const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const SubscribeBlock: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState("");

    const subscribe = (e: any) => {
        e.preventDefault();
        setLoading(true);
        axios({
            url: "/api/feedback/email",
            method: "post",
            data: { email }
        }).then(({data}) => {
            setSuccess(true)
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className="subscribe-block-wrapper content-wrapper">
            <form className="subscribe-block" onSubmit={subscribe}>
                <input
                    name="email"
                    className="subscribe-block-input"
                    value={email}
                    disabled={success || loading}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
                <button
                    type="submit"
                    className="main-btn"
                    disabled={!regexp.test(email) || success || loading}
                    children={success ? "Вы подписаны" : "Подписаться на рассылку"}
                />
            </form>
        </div>
    )
}