import { useState } from "react"

const getShow = () => {
    const show = localStorage.getItem("show-popunder");
    return !show;
}


const CtaPopunder: React.FC = () => {
    const [show, setShow] = useState(() => getShow());
    
    const hide = () => {
        localStorage.setItem("show-popunder", "hidden");
        setShow(false);
    }

    return (
        <div className={`cta-popunder ${show ? "show" : ""}`}>
            <button className="close" onClick={hide}>×</button>
            <p className="text">Закажи звонок и получи эксклюзивное предложение</p>
        </div>
    )
}

export default CtaPopunder;