import { Outlet } from "react-router";
import { Header } from "../components/header";
import { SiteFooter } from "../components/footer";
import CtaPopunder from "../components/cta-popunder";

const BasePage: React.FC = () => {
    return (
        <div className="app">
            <Header />
            <Outlet />
            <CtaPopunder />
            <SiteFooter />
        </div>
    )
}

export default BasePage;