
export interface IMicrodata {
    title: string
    description: string
    id: string
    url: string
    image: string
    price: number
}

export const Microdata: React.FC<IMicrodata> = (data) => {
    const formattedPrice = data.price.toFixed(2);
    return (
        <div itemScope itemType="http://schema.org/Product">
            <meta itemProp="name" content={data.title} />
            <meta itemProp="brand" content="Estate-Phuket" />
            <meta itemProp="description" content={data.description} />
            <meta itemProp="productID" content={data.id} />
            <meta itemProp="url" content={data.url} />
            <meta itemProp="image" content={data.image} />
            <div itemScope itemProp="offers" itemType="http://schema.org/Offer">
                <meta itemProp="availability" content="in stock" />
                <meta itemProp="itemCondition" content="new" />
                <meta itemProp="price" content={formattedPrice} />
                <meta itemProp="priceCurrency" content="USD" />
            </div>
        </div>
    )
}