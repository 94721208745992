import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Post } from "../api/types";
import { Preloader } from "../components/preloader";
import { CDN_BASE } from "..";
import { formatDate } from "./blog";


const PostPreloader: React.FC = () => {
    return (
        <div className="post-preloader">
            <Preloader />
        </div>
    )
}

const PostContent: React.FC<{post: Post}> = ({post}) => {
    return (
        <section className="post-wrapper">
            <div className="post-cover" style={{
                backgroundImage: `url('${CDN_BASE + post.image}')`
            }} />
            <div className="post">
                <p className="post-created">{formatDate(post.createdAt)}</p>
                <h2 className="post-title">{post.title}</h2>
                <p className="post-content">{post.content}</p>
                <Link className="link-btn" to="/blog">Вернуться к блогу</Link>
            </div>
        </section>
    )
}

const PostPage: React.FC = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [post, setPost] = useState<null | Post>(null);

    useEffect(() => {
        setLoading(true);
        axios<Post>({
            url: `/api/article/${id}`,
            method: "get"
        }).then(({data}) => {
            console.log("data", data)
            setPost(data);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }, [id]);
    
    
    return (
        <div className="page-content">
        <section className="jumbo-wrapper post-jumbo">
            <div className="jumbo content-wrapper">
                <h2 className="heading">{post ? post.title : ""}</h2>
            </div>
        </section>
        <div className="content-wrapper">
            <section className="post-wrapper">
                {
                    loading
                        ? <PostPreloader />
                        : post
                            ? <PostContent post={post} />
                            : <PostPreloader />
                }
            </section>
        </div>
    </div>
    )
}

export default PostPage;