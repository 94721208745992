import { useEffect, useState } from "react";
import { Estate } from "../api/types";
import axios from "axios";
import { Preloader } from "../components/preloader";
import { EstateCard } from "../components/estate-card";
import { LineSelector } from "../components/line-selector";
import { ButtonsSelector } from "../components/buttons-selector";
import { DropdownSelector } from "../components/dropdown-selector";

const EstatesGridEmpty: React.FC = () => {
    return (
        <div className="estates-grid-empty-label">
            Нет объектов
        </div>
    )
}


const EstatesGrid: React.FC<{estates: Estate[]}> = ({estates}) => {
    return (
        <section className="estates-grid-wrapper">
            <div className={`content-wrapper ${estates.length ? "estates-grid" : "estates-grid-empty"}`}>
                {
                    estates.length
                        ? estates.map(estate => <EstateCard key={estate._id} estate={estate} />)
                        : <EstatesGridEmpty />
                }
            </div>
        </section>
    )
}

const TYPE_OPTIONS = [
    {
        title: "Все",
        value: "",
    },
    {
        title: "Виллы",
        value: "villa",
    },
    {
        title: "Апартаменты",
        value: "appartment",
    },
    {
        title: "Участок",
        value: "land",
    }
];

const BEDROOMS_OPTIONS = [
    {
        title: '1',
        value: '1',
    },
    {
        title: '2',
        value: '2',
    },
    {
        title: '3',
        value: '3',
    },
    {
        title: '4',
        value: '4',
    },
    {
        title: '5',
        value: '5',
    },
]

const FLOORS_OPTIONS = [
    {
        title: '1',
        value: '1',
    },
    {
        title: '2',
        value: '2',
    },
    {
        title: '3',
        value: '3',
    },
]

const SQUARE_OPTIONS = [
    {
        title: "Любая",
        value: ""
    },
    {
        title: "до 200м²",
        value: "0-200",
    },
    {
        title: "200-300м²",
        value: "200-300",
    },
    {
        title: "300-500м²",
        value: "300-500",
    },
    {
        title: "500-800м²",
        value: "500-800",
    },
    {
        title: "от 800м²",
        value: "800-9999"
    }
]

const PRICE_OPTIONS = [
    {
        title: "Любая",
        value: ""
    },
    {
        title: "до $200к",
        value: "0-200000",
    },
    {
        title: "$200-400к",
        value: "200000-400000",
    },
    {
        title: "$400-800к",
        value: "400000-800000",
    },
    {
        title: "$800-1200к",
        value: "800000-1200000",
    },
    {
        title: "от $1.2м",
        value: "1200000-9999000"
    }
]


const CatalogPage: React.FC = () => {
    const [square, setSquare] = useState(() => SQUARE_OPTIONS[0].value);
    const [price, setPrice] = useState(() => PRICE_OPTIONS[0].value);
    const [type, setType] = useState(() => TYPE_OPTIONS[0].value);
    const [bedrooms, setBedrooms] = useState<string[]>([]);
    const [floors, setFloors] = useState<string[]>([]);

    const [estates, setEstates] = useState<Estate[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const controller = new AbortController();

        axios<Estate[]>({
            url: `/api/real-estate/filter`,
            method: "get",
            signal: controller.signal,
            params: {
                minSquare: square !== "" ? square.split("-")[0] : null,
                maxSquare: square !== "" ? square.split("-")[1] : null,
                minPrice: price !== "" ? price.split("-")[0] : null,
                maxPrice: price !== "" ? price.split("-")[1] : null,
                type: type !== "" ? type : null,
                bedrooms: bedrooms,
                floors: floors,
            }
        }).then(({data}) => {
            setEstates(data);
            setLoading(false);
        }).catch(err => {
            console.error(err);
        })
        return () => {
            controller.abort()
        }
    }, [square, price, type, bedrooms, floors]);

    return (
        <div className="page-content">
            <section className="jumbo-wrapper catalog-jumbo">
            <div className="jumbo content-wrapper">
                <h2 className="heading">Гарантированный доход<br />от ваших вложений</h2>
                <div className="type-line-selector">
                    <LineSelector 
                        value={type}
                        onChange={setType}
                        options={TYPE_OPTIONS}
                    />
                </div>
            </div>
            <div className="estate-filter-wrapper content-wrapper">
                <div className="estate-filter">
                    <div className="square-selector">
                        <span className="label">Площадь:</span>
                        <DropdownSelector
                            value={square}
                            onChange={setSquare}
                            options={SQUARE_OPTIONS}
                        />
                    </div>
                    <div className="price-selector">
                        <span className="label">Цена:</span>
                        <DropdownSelector
                            value={price}
                            onChange={setPrice}
                            options={PRICE_OPTIONS}
                        />
                    </div>
                    <div className="bedrooms-selector">
                        <span className="label">Спален:</span>
                        <ButtonsSelector
                            value={bedrooms}
                            onChange={setBedrooms}
                            options={BEDROOMS_OPTIONS}
                        />
                    </div>
                    <div className="floors-selector">
                        <span className="label">Этажей:</span>
                        <ButtonsSelector
                            value={floors}
                            onChange={setFloors}
                            options={FLOORS_OPTIONS}
                        />
                    </div>
                </div>
            </div>
        </section>
            <div className="content-wrapper">
                <h4 className="catalog-title">Каталог</h4>
            </div>
            {
                loading
                    ? <div className="grid-preloader"><Preloader /></div>
                    : <EstatesGrid estates={estates} />
            }
        </div>
    )
}

export default CatalogPage;