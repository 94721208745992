import { useState } from "react"
import { Link, NavLink } from "react-router-dom"

export interface INavLink {
    title: string
    link: string
}

export const MENU: INavLink[] = [
    {
        title: "Каталог",
        link: "/catalog"
    },
    {
        title: "О компании",
        link: "/about"
    },
    {
        title: "Блог",
        link: "/blog"
    },
    {
        title: "Контакты",
        link: "/contact"
    },
]

export const HeaderNavLink: React.FC<INavLink> = ({title, link}) => {
    return (
        <NavLink
            className={({isActive}) => `nav-link ${isActive ? 'active' : ''}`}
            children={title}
            to={link}
        />
    )
}

export const HeaderNav: React.FC<{menu: INavLink[]}> = ({menu}) => {
    return (
        <nav className="header-nav">
            {
                menu.map(link => <HeaderNavLink key={link.link} {...link} />)
            }
        </nav>
    )
}

export const FooterNav: React.FC<{menu: INavLink[]}> = ({menu}) => {
    return (
        <nav className="footer-nav">
            {
                menu.map(link => <HeaderNavLink key={link.link} {...link} />)
            }
        </nav>
    )
}

export const Header: React.FC = () => {
    const [mobileMenuOpened, setOpened] = useState(false);

    return (
        <>
        <header className="site-header-wrapper">
            <div className="site-header content-wrapper">
                <Link to="/">
                    <img src="/img/logo.svg" alt="logo" className="header-logo" />
                </Link>
                <HeaderNav menu={MENU} />
                <a href="https://wa.me/message/6GKKRLYY44RDJ1" target="_blank" className="header-contact" rel="noreferrer">
                    <span className="platform">WhatsApp:</span>
                    <span className="tel">+65 66 015 9388</span>
                </a>
                <a href="https://t.me/tophuket" className="tg-icon" target="_blank" rel="noreferrer">
                </a>
                <div className="mobile-menu-toggle" onClick={() => setOpened(true)}>
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                </div>
            </div>
        </header>
        <div className={`mobile-menu-wrapper ${mobileMenuOpened ? "opened" : ""}`}>
            <div className="mobile-menu">
                <img src="/img/xmark.svg" className="close-icon" alt="x" onClick={() => setOpened(false)} />
                <nav className="header-nav" onClick={() => setOpened(false)}>
                    {
                        MENU.map(link => <HeaderNavLink key={link.link} {...link} />)
                    }
                </nav>
                <a href="https://wa.me/message/6GKKRLYY44RDJ1" target="_blank" className="header-contact" rel="noreferrer">
                    <span className="platform">WhatsApp:</span>
                    <span className="tel">+65 66 015 9388</span>
                </a>
            </div>
        </div>
        </>
    )
}