
export interface ContactCardProps {
    icon: string
    title: string
    content: string
}

const CARDS: ContactCardProps[] = [
    {
        icon: '/img/phone-arrow-down-left.svg',
        title: 'Связаться по телефону:',
        content: '+65 66 015 9388',
    },
    {
        icon: '/img/envelope-dot.svg',
        title: 'Электронная почта:',
        content: 'info@phuket-estate.ru',
    },
    {
        icon: '/img/map-location-dot.svg',
        title: 'Адрес офиса:',
        content: 'Golf Land View 88, Phuket, Thailand'
    },
]

const ContactCard: React.FC<ContactCardProps> = (props) => {
    return (
        <div className="contact-card">
            <div className="icon" style={{
                backgroundImage: `url('${props.icon}')`
            }} />
            <div className="card-content">
                <h4 className="card-title">{props.title}</h4>
                <p className="card-value">{props.content}</p>
            </div>
        </div>
    )
}

export const ContactMap: React.FC = () => {
    return (
        <section className="contact-map-wrapper">
            <div className="contact-cards content-wrapper">
                {
                    CARDS.map(card => <ContactCard {...card} key={card.title} />)
                }
            </div>
        </section>
    )
}