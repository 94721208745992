import axios from "axios";
import { useState } from "react";


export const BlockContactForm: React.FC = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [content, setContent] = useState("");
    
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const sendMessage = () => {
        setLoading(true);
        axios({
            url: "/api/feedback/full",
            method: "post",
            data: {
                name,
                phone,
                content
            }
        }).then(() => {
            setSuccess(true);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    const isActive = name.length && content.length && phone.length;

    return (
        <div className="contact-form block-contact-form">
            <div className="contact-header">
                <h4 className="title">Наша команда подберёт варианты под ваш бюджет</h4>
                <p className="text">Оставьте свои контактные данные в форме, и мы свяжемся с Вами в ближайшее время.</p>
            </div>
            <input
                className="contact-form-input"
                placeholder="Ваше имя"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <input
                className="contact-form-input"
                placeholder="Номер телефона"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
            />
            <textarea
                className="contact-form-input textarea"
                placeholder="Сообщение"
                value={content}
                onChange={(e) => setContent(e.target.value)}
            />
            <button
                className={`main-btn ${loading ? "loading" : ""}`}
                disabled={!isActive || success}
                onClick={sendMessage}
                children={`Отправить сообщение`}
            />
        </div>
    )
}