import { useEffect, useState } from "react";
import { EstateSlider } from "../components/estate-slider";
import { Jumbo } from "../components/jumbo";
import { Estate } from "../api/types";
import axios from "axios";
import { AboutSection } from "../components/about-section";
import { Link } from "react-router-dom";

const HomePage: React.FC = () => {
    const [estates, setEstates] = useState<Estate[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios<Estate[]>({
            url: "/api/real-estate",
            method: "get"
        }).then(({data}) => {
            setEstates(data);
            setLoading(false);
        }).catch(err => {
            console.error(err);
        })
    }, []);

    return (
        <div className="page-content">
            <Jumbo />
            <div className="sliders-wrapper">
                <EstateSlider
                    title="Избранные объeкты"
                    loading={loading}
                    estates={estates.filter(estate => estate.starred).slice(0,5)}
                />
                <EstateSlider
                    title="Каталог"
                    loading={loading}
                    estates={estates.filter(estate => !estate.starred).slice(0,5)}
                />
                <div className="show-more-wrapper">
                    <Link
                        className="main-btn"
                        to="/catalog"
                        children="Перейти к каталогу"
                    />
                </div>
                <AboutSection />
            </div>
        </div>
    )
}

export default HomePage;
