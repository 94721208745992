import { Estate } from "../api/types"
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { useEffect, useState } from "react";
import useMobile from "../hooks/useMobile";
import { Preloader } from "./preloader";
import { EstateCard } from "./estate-card";

const SliderControls: React.FC = () => {
    const swiper = useSwiper();

    const [isBeginning, setIsBeginning] = useState(swiper.isBeginning);
    const [isEnd, setIsEnd] = useState(swiper.isEnd);

    useEffect(() => {
        const onIndexChange = () => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
        }

        swiper.on("activeIndexChange", onIndexChange);

        return () => {
            swiper.off("activeIndexChange", onIndexChange);
        }

    }, [swiper]);

    return (
        <div className="slider-controls">
            <button
                className="slider-slide-btn"
                disabled={isBeginning}
                onClick={() => swiper.slidePrev()}
                children={
                    <img src="/img/arrow-left-white.svg" className="prev-btn" alt="<" /> 
                }
            />
            <button
                className="slider-slide-btn"
                disabled={isEnd}
                onClick={() => swiper.slideNext()}
                children={
                    <img src="/img/arrow-right-white.svg" className="next-btn" alt=">" /> 
                }
            />
        </div>
    )
}

const SliderPreloader: React.FC = () => {
    return (
        <div className="slider-preloader">
            <Preloader />
        </div>
    )
}

export const EstateSlider: React.FC<{
    title: string,
    estates: Estate[],
    loading: boolean
}> = ({title, estates, loading}) => {
    const [s] = useMobile(600);
    const [m] = useMobile(900);
    const [l] = useMobile(1200);

    return (
        <section className="estate-slider-wrapper">
            <div className="estate-slider content-wrapper">
                <h2 className="slider-title">{title}</h2>
                {
                    loading
                        ? <SliderPreloader />
                        : <Swiper
                            className="slider"
                            modules={[Pagination]}
                            spaceBetween={40}
                            slidesPerView={l ? m ? s ? 1 : 2 : 3 : 4}
                            pagination={{ clickable: true }}
                        >
                            {
                                estates.map(estate => (
                                    <SwiperSlide key={title+estate._id} className="slide estate-slide">
                                        <EstateCard estate={estate} />
                                    </SwiperSlide>
                                ))
                            }
                            <SliderControls />
                        </Swiper>
                }
            </div>
        </section>
    )
}