import { useEffect, useState } from "react";
import { Preloader } from "../components/preloader";
import { SubscribeBlock } from "../components/subscribe-block";
import { Post } from "../api/types";
import axios from "axios";
import { Link } from "react-router-dom";
import { CDN_BASE } from "..";

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
}

const BlogPreloader: React.FC = () => {
    return (
        <div className="blog-preloader">
            <Preloader />
        </div>
    )
}

const BlogPostsEmpty: React.FC = () => {
    return (
        <div className="blog-posts-empty">
            <span className="blog-posts-empty-label">Пока нет публикаций</span>
        </div>
    )
} 

const BlogPostItem: React.FC<{post: Post}> = ({post}) => {
    return (
        <article className="blog-post-item">
            <div className="cover" style={{
                backgroundImage: `url('${CDN_BASE + post.image}')`
            }} />
            <div className="post">
                <p className="post-created">{formatDate(post.createdAt)}</p>
                <h4 className="post-title">{post.title}</h4>
                <p className="post-content">{post.short}</p>
                <Link className="link-btn" to={`/blog/${post._id}`}>Подробнее</Link>
            </div>
        </article>
    )
}

const BlogPage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState<Post[]>([]);

    useEffect(() => {
        setLoading(true);
        axios<Post[]>({
            url: "/api/article",
            method: "get"
        }).then(({data}) => {
            setPosts(data);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }, []);
    
    return (
        <div className="page-content">
            <section className="jumbo-wrapper blog-jumbo">
                <div className="jumbo content-wrapper">
                    <h2 className="heading">Блог</h2>
                    <h4 className="subheading">Подпишись на рассылку. Получай ежемесячно горячие предложения и полезные статьи о недвижимости в Тиланде</h4>
                    <SubscribeBlock />
                </div>
            </section>
            <div className="content-wrapper">
                <section className="blog-posts-wrapper">
                    {
                        loading
                            ? <BlogPreloader />
                            : posts.length
                                ? posts.map(post => <BlogPostItem key={post._id} post={post} />)
                                : <BlogPostsEmpty />
                    }
                </section>
            </div>
        </div>
    )
}

export default BlogPage;
