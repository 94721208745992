import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import React, { Suspense, lazy, useLayoutEffect } from "react";

import BasePage from "./base";
import HomePage from "./home";
import CatalogPage from "./catalog";
import EstatePage from "./estate";
import BlogPage from "./blog";
import PostPage from "./post";
import ContactPage from "./contact";
import AboutPage from "./about";
import { Preloader } from "../components/preloader";
import PrivacyPage from "./privacy";

const AdminRouter = lazy(() => import("./admin/index"));

interface IProtectedRoute {
    condition: boolean
    redirect: string
    children: JSX.Element | JSX.Element[]
}


const ScrollToTop: React.FC<{children: JSX.Element}> = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}


export const ProtectedRoute: React.FC<IProtectedRoute> = ({condition, redirect, children}) => {
    return (
        <>
            {condition ? children : <Navigate to={redirect} replace />}
        </>
    )
}

const PagePreloader: React.FC = () => {
    return (
        <div className="page-preloader">
            <Preloader />
        </div>
    )
}

const AdminRouterWrapper: React.FC = () => {
    return (
        <Suspense fallback={ <PagePreloader /> }>
            <AdminRouter />
        </Suspense>
    )
}

const Router: React.FC<{}> = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route
                    path="/admin/*"
                    element={ <AdminRouterWrapper /> }
                />
                <Route element={ <BasePage /> }>
                    <Route
                        path="/"
                        element={ <HomePage /> }
                    />
                    <Route
                        path="/privacy"
                        element={ <PrivacyPage /> }
                    />
                    <Route
                        path="/catalog"
                        element={ <CatalogPage /> }
                    />
                    <Route
                        path="/catalog/:id"
                        element={ <EstatePage /> }
                    />
                    <Route
                        path="/contact"
                        element={ <ContactPage /> }
                    />
                    <Route
                        path="/about"
                        element={ <AboutPage /> }
                    />
                    <Route
                        path="/blog"
                        element={ <BlogPage /> }
                    />
                    <Route
                        path="/blog/:id"
                        element={ <PostPage /> }
                    />
                    <Route
                        path="*"
                        element={ <Navigate to="/" replace /> } 
                    />
                </Route>
            </Routes>
        </ScrollToTop>
    )
}

export default Router;